import { LinkedDevice } from '@/model';
import { defineStore } from 'pinia';
import { Mutex } from 'async-mutex';

import { isError, getLinkedDevices } from '@/services/backend';

interface State {
  status: 'pending' | 'error' | 'ready';
  awaitable: Promise<void>;
  error: Error;
  devices: LinkedDevice[]; // not using sum type because vue encourages SFC only
  _mutex: Mutex;
}

export const useLinkedDevicesStore = () => {
  const innerStore = defineStore('linkedDevices', {
    state: (): State => ({
      devices: [],
      status: 'pending',
      awaitable: Promise.resolve(),
      error: new Error(),
      _mutex: new Mutex(),
    }),
    getters: {
      routers(state): LinkedDevice[] {
        // TODO: revert vendor === miri whenever we start to trust the daemon again
        return state.devices.filter(d => d.role === 'router' || d.vendor === 'miri');
      },
    },
    actions: {
      async refresh() {
        let didRun = false;
        let resp;

        // cannot guarantee this remote call executes only once
        if (this._mutex.isLocked()) return;
        await this._mutex.runExclusive(async () => {
          resp = await getLinkedDevices();
          didRun = true;
        });

        if (didRun) {
          if (isError(resp)) {
            this.status = 'error';
            this.error = new Error('Error fetching linked devices', { cause: resp });
          } else {
            this.status = 'ready';
            return (this.devices = resp!);
          }
        }
      },
      _init() {
        this.awaitable = this.refresh();
      },
      async updateLinkedDeviceAssociation(uuid: string, referenceId: string | null) {
        await this._mutex.runExclusive(() => {
          this.devices.forEach(d => {
            if (d.deviceUUID === uuid) d.routerReferenceId = referenceId;
          });
        });
      },
    },
  });

  const store = innerStore();
  if (store.status === 'pending') store._init();

  return store;
};
