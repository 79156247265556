<template>
  <div class="card px-sm-5 px-3 py-5 text-center text-md-center width-md-400 d-flex align-items-center">
    <h2 class="card-title my-3">Welcome!</h2>
    <small v-if="hasSub">
      A subscription has been found with {{ email }}.
      <br>
      Redirecting to the Account Dashboard...
    </small>
    <div v-else>
    <small>We did not find a plan associated with that email address ({{ email }}).</small>
    <a
      type="button"
      class="btn btn-primary btn-speedify-blue my-4"
      style="display: block; width: 100%; border: none"
      href="https://speedify.com/store"
    >
      View Plans and Pricing
    </a>

    <h6 class="my-2">Already have a Plan?</h6>
    <router-link to="/login?prompt=login" class="btn btn-speedify-blue-outline mb-4" style="width: 100%">
      Sign in with a different account
    </router-link>
    </div>
  </div>
</template>

<script setup lang="ts" charset="utf-8">
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

import { getToken } from '@/services/backend';
import { useUserStore } from '@/store/user';

const router = useRouter();
const email = ref<string | null>(null);
const hasSub = ref(false);

onMounted(async () => {
  // TODO: check weird login status in AuthGuard and router guards
  const token = await getToken();
  if (!token?.email) return await router.push('/login');

  email.value = token.email;

  const userStore = useUserStore();
  await userStore.refresh();
  hasSub.value = !!userStore.subscriptions.length;

  if (hasSub.value) {
    setTimeout(() => void router.push('/account'), 2000);
  }
});
</script>
