<template><div></div></template>

<script lang="ts" setup>
import { provide, onMounted, ref, markRaw } from 'vue';
import { useRoute } from 'vue-router';
import { useDialog } from 'primevue/usedialog';
import * as Sentry from '@sentry/vue'

import RouterPlansPricing from '@/components/RouterPlansPricing.vue';
import RouterPlansPricingHeader from './RouterPlansPricingHeader.vue';
import RouterPlanConfirmation from '@/components/RouterPlanConfirmation.vue';
import RouterPartner90DayActivated from '@/components/RouterPartner90DayActivated.vue';

import RouterLicenseRequired from '@/components/banners/RouterLicenseRequired.vue';
import RouterPoweredBySpeedifyActivated from '@/components/banners/RouterPoweredBySpeedifyActivated.vue';

import { useUserStore } from '@/store/user';
import { useLinkedDevicesStore } from '@/store/linkedDevices';
import { modal } from '@/symbol';
import { clearUrlHash } from '@/util';
import { useBanner } from '@/components/usebanner';
import { getToken, setShown90DaySuccess } from '@/services/backend';
import { config } from '@/config';
import { storeToRefs } from 'pinia';

const route = useRoute();
const vendors = config.partnerRouterVendors;
const userStore = useUserStore();
const linkedDevicesStore = useLinkedDevicesStore();
const currModal = ref<string | null>(null);
const data = ref<any>({});
const showModal = (name: string | null) => (currModal.value = name);
provide(modal, { currModal, data, showModal });

const dialog = useDialog();
const banner = useBanner();

const dispatch = async () => {
  await userStore.refresh();

  // TODO: abstract router & vendor checks
  const { subscriptions } = storeToRefs(userStore);
  const subscription = subscriptions.value.find(s => s.productName.match(/router/i));

  const numRouterSubs = userStore.routerQty;
  const payload = await getToken();

  const hash = Object.fromEntries(new URLSearchParams(window.location.hash.substring(1)).entries());

  const vendor = vendors.includes(hash.vendor as string) && hash.vendor || undefined;
  if (payload?.role === 'router' && vendor && hash.deviceUUID) {
    banner.open(RouterPoweredBySpeedifyActivated, { props: { vendor } });
  }

  // TODO: abstract conditions
  if (payload?.role === 'router' && numRouterSubs === 0) {
    banner.open(RouterLicenseRequired, { props: { subscription } });
  } else if (payload?.role === 'router' && numRouterSubs < linkedDevicesStore.routers.length) {
    banner.open(RouterLicenseRequired, { props: { subscription } });
  }

  if (hash['partner-90-day-activated'] === 'true' && hash['has-shown-90day-success'] !== 'true') {
    setShown90DaySuccess(hash.deviceUUID as string)
      .catch(err => Sentry.captureException(err, { extra: { route } }));

    dialog.open(RouterPartner90DayActivated, {
      props: {
        modal: true,
        draggable: false,
        header: 'Success!',
      },
      onClose: clearUrlHash,
    });
    return;
  }

  if (hash['show-router-dialogue'] === 'true') {
    dialog.open(RouterPlansPricing, {
      props: {
        modal: true,
        draggable: false,
      },
      templates: { header: markRaw(RouterPlansPricingHeader) },
      onClose: clearUrlHash,
    });
    return;
  }

  if (hash['router-purchase-plan']) {
    dialog.open(RouterPlanConfirmation, {
      props: {
        modal: true,
        draggable: false,
        header: 'Please confirm your change',
      },
      onClose: clearUrlHash,
    });
    return;
  }
};

onMounted(dispatch);
window.addEventListener('hashchange', dispatch as any);
</script>
