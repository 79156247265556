<template>
  <div class="card-body d-flex flex-wrap align-content-between pt-4 pb-2">
    <div v-if="upgrade.blackfriday24EligibleUpgrade" class="w-100">
      <h3>SEASON  OF SAVINGS</h3>
    </div>
    <div class="w-100">
      <h2>{{ upgrade.taglineDuration }}</h2>
    </div>
    <div v-if="!upgrade.blackfriday24EligibleUpgrade" class="w-100 mt-2">
      <h1>${{ (Number(upgrade.price.replace('$', '')) / (upgrade.duration || 1)).toFixed(2) }}</h1>
    </div>
    <div v-else class="w-100 mt-2">
      <h1>${{ (Number(upgrade.discountPrice.replace('$', '')) / (upgrade.duration || 1)).toFixed(2) }}</h1>
    </div>
    <div class="w-100 mb-2">
      <h4>per month</h4>
    </div>
    <div v-if="!upgrade.blackfriday24EligibleUpgrade" class="w-100">
      <h4>{{ upgrade.price }} {{ upgrade.taglineBilling }}</h4>
    </div>
    <div v-if="upgrade.blackfriday24EligibleUpgrade" class="w-100">
      <h4><strike>{{ upgrade.price }}</strike> {{ upgrade.discountPrice }} {{ upgrade.taglineBilling }}</h4>
    </div>
      <!--
      pulled this out from below, doesn't seem like it's needed
      :class="{
        'disabled': postSuccess || postPending || postError,
      }"
      -->
    <div class="w-100 d-flex justify-content-center mb-2">
      <button
        type="button"
        class="btn btn-speedify-outline mt-3 stm-btn-upgrade-confirm"
        @click="setUpgradeSelection"
      >
        Upgrade
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import useUpgradeSelection from '@/store/upgradeSelection';
import { Product } from '@/model';

const props = defineProps<{ upgrade: Product }>();

const emit = defineEmits(['showUpgradeConfirmationModal']);

const upgradeSelection = useUpgradeSelection();

const setUpgradeSelection = () => {
  emit('showUpgradeConfirmationModal', 'confirmUpgradeModal');
  // @ts-expect-error FIXME: define UpgradeDetails in mode.ts
  upgradeSelection.set(props.upgrade.planCode, props.upgrade);
};
</script>
