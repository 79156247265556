<template>
  <Dialog
    v-model:visible="visible"
    modal
    :closable="true"
    :draggable="false"
    header="Here’s an offer for 15% off your Speedify Plan"
    pt:title:class="fw-bold white-space-nowrap mx-auto mt-4 fs-4"
  >
    <div class="container-fluid">
      <div class="d-flex row justify-content-center">
        <div class="col-12 col-sm-6" style="flex: 1 1 0">
          <!-- TODO: do not hardcode 15% -->
          <span class="fw-bold d-flex justify-content-center">Save an additional 15%, no sweat</span>
          <p>
            We don’t want you to go! That’s why we’re offering you to keep your Speedify for Families Yearly plan with a permanent
            discount of 15% off!
          </p>
          <span class="fw-bold">Current Plan</span>

          <div class="container">
            <div class="d-flex justify-content-between">
              <span>
                {{ humanReadableProductName(subscription.productName) }}
                {{ hyphenate(capitalizeFirstChar(subscriptionPeriod(subscription.productName))) }}
              </span>
              <span v-if="pricing" class="fw-bold">
                {{ pricing!.currencyCode }}{{ format(pricing!.current) }}/{{ pricing!.unit }}
              </span>
              <Skeleton v-else />
            </div>
            <div class="d-flex justify-content-between highlight-bar">
              <span>Discount</span>
              <span v-if="pricing" class="fw-bold">
                -{{ pricing!.currencyCode }}{{ format(pricing!.off) }}/{{ pricing!.unit }}
              </span>
              <Skeleton v-else />
            </div>
            <div class="d-flex justify-content-between fw-bold">
              <span>New Total</span>
              <span v-if="pricing">{{ pricing!.currencyCode }}{{ format(pricing!.newTotal) }}/{{ pricing!.unit }}</span>
              <Skeleton v-else />
            </div>
          </div>
        </div>

        <img class="col-12 col-sm-6" src="/images/present.png" style="object-fit: cover;" />
      </div>
    </div>

    <template #footer>
      <div class="container mb-4">
        <div class="row column-gap-3">
          <Button
            label="Lock in 15% off Speedify"
            class="col btn btn-speedify-blue stm-btn-cancelation-flow-pause-1"
            style="flex: 1 1 0"
            @click="applyCancellationDiscount()"
          />
          <Button
            label="No Thanks, Cancel Subscription"
            class="col btn btn-speedify-grey stm-btn-cancelation-flow-go-back-1"
            style="flex: 1 1 0"
            @click="showModal('AreYouSure')"
          />
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { computed, inject, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Skeleton from 'primevue/skeleton';

import { getCouponForSubscription, redeemCoupon } from '@/services/backend';
import { useUserStore } from '@/store/user';
import { modal } from '@/symbol';
import { subscriptionPeriod, humanReadableProductName } from '@/services/subscription';
import { hyphenate, capitalizeFirstChar } from '@/services/string';
import { Coupon } from 'recurly';

const route = useRoute();
const { currModal, showModal } = inject(modal)!;
const visible = computed({
  get: () => currModal.value === 'Offer',
  set: () => currModal.value = null,
});
const coupon = ref<Coupon>();
const emit = defineEmits<{
  'tried-coupon': [result: 'success' | 'failure', coupon?: any, message?: string | Error];
}>();
const pricing = ref<{
  currencyCode: string;
  current: number;
  discount: number;
  off: number;
  newTotal: number;
  unit: string;
}>();
// eslint-disable-next-line @typescript-eslint/unbound-method
const format = new Intl.NumberFormat(navigator.languages, { maximumFractionDigits: 2 }).format;

let { subscriptions } = useUserStore();
const subscription = computed(() => subscriptions.find(s => s.referenceId === route.query.referenceId)!);

onMounted(async () => {
  if (subscription.value.type === 'dedicated_server' || subscription.value.type === 'router') {
    return;
  }

  const cpn = await getCouponForSubscription(subscription.value.subscriptionId!); // REC storeType only
  coupon.value = cpn.coupon;
  if (coupon.value.discount!.type !== 'percent') throw new Error('unimmplemented');

  const invoice = subscription.value.invoices.toSorted((a, b) => (new Date(a.createdAt) > new Date(b.createdAt) ? 1 : 0))[0];
  const currentProductPrice = Number(subscription.value.upgradeDetails!.currentProduct.price.replace('$', ''));
  const off = currentProductPrice * (coupon.value.discount!.percent! / 100);

  const unit = {
    'monthly ': 'mo',
    'yearly ': 'yr',
    '3 years ': '3yr', // 3 year subscriptions won't see this modal
    '': '',
  }[subscriptionPeriod(subscription.value.productName)];
  pricing.value = {
    currencyCode: invoice.currencyCode,
    current: currentProductPrice,
    discount: coupon.value.discount!.percent!,
    off,
    newTotal: currentProductPrice - off,
    unit,
  };
});

const applyCancellationDiscount = async () => {
  const result = await redeemCoupon(subscription.value.accountId, subscription.value.subscriptionId!, coupon.value!.id!);
  if (result.error) {
    emit('tried-coupon', 'failure', undefined, result.message);
  } else {
    emit('tried-coupon', 'success', coupon.value, undefined);
  }
  showModal(null);
};
</script>

<style scoped media="screen">
.highlight-bar {
  background: #00f4ae33;
  border-radius: 10px;
  padding: 0 8px 0 8px;
  margin: 0 -8px 0 -8px;
}
</style>

<style media="screen">
@media (max-width: 704px) {
  div.p-dialog { width: 75vw; }
  div.p-dialog img { max-width: 192px !important; order: -1; }
  div.p-dialog > div.container-fluid > div.row:last-child { order: 1 }
  div.p-dialog span.fw-bold { margin: 4px 0 4px 0; }
}
@media (min-width: 704px) {
  div.p-dialog { width: 50vw; }
  div.p-dialog img { transform: scale(-1, 1); }
}
</style>
