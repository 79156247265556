<template>
  <div class="container-lg px-2 px-md-5">
    <div class="row mx-sm-3 mt-5 mx-0 mb-3 justify-content-center align-items-stretch">
      <div class="col-md-7 col-lg-6 col-xl-5 px-1 px-sm-2 mb-2 d-flex justify-content-center flex-wrap">
        <div class="card px-sm-5 px-3 py-5 text-center text-md-center width-md-400 d-flex align-items-center">
          <small>Signing in as:</small>
          <small class="fw-bold">{{ email }}</small>
          <a class="btn btn-primary btn-speedify-blue my-3" style="border: none; width: 100%" @click="refresh()">Continue</a>
          <router-link class="text-decoration-none speedify-blue" :to="to">Use another account</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router';

import wretch from 'wretch';

const route = useRoute();
const router = useRouter();
const to = { name: 'Login', query: { redirect: route.fullPath } };

defineProps<{ email: string }>();
const emit = defineEmits<{ (_: 'token-refreshed'): void }>();
const refresh = () =>
  wretch('/api/token/refresh')
    .get()
    .res(() => emit('token-refreshed'))
    // FIXME: effectively it just clicks "Use another account"
    // We still need to figure out why the token cookie can be invalid
    // without manual mutation to it.
    .catch(() => router.push(to));
</script>
