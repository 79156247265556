<template>
  <div v-if="showBody" class="card">
    <h2 class="card-title my-3">{{ title }}</h2>
    <div class="card-body">
      <div
        v-if="route.query.passwordChanged === 'true'"
        class="alert alert-success alert-dismissible fade show width-md-400 width-sm-card"
        role="alert"
      >
        Password has been updated successfully!
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" />
      </div>

      <small v-if="route.query.flow === 'register'" class="mt-2 mb-4">
        If you don't already have a Speedify account, click Sign In with Apple or Sign In with Google to create a new account
      </small>
      <small v-else class="mt-2 mb-4">
        Don't have a Speedify account?
        <a type="button" :href="storeurl" class="text-decoration-none speedify-blue" target="_blank">
          Get Started
        </a>
      </small>

      <form @submit.prevent="login">
        <div class="input-group mb-2">
          <span id="email-addon" class="input-group-text"><i class="fas fa-user"></i></span>
          <input
            v-model.trim.lazy="username"
            type="email"
            autocomplete="email"
            class="form-control"
            placeholder="Email"
            name="username"
            aria-label="Email"
            aria-describedby="email-addon"
            required
            aria-required="true"
          />
        </div>
        <div class="input-group mb-2">
          <span id="password-addon" class="input-group-text"><i class="fas fa-lock"></i></span>
          <input
            type="password"
            autocomplete="current-password"
            class="form-control"
            placeholder="Password"
            name="password"
            aria-label="Password"
            aria-describedby="password-addon"
            required
            minlength="8"
            :oninput="() => (state.error = null)"
            aria-required="true"
          />
        </div>
        <!-- show any messages that come back with authentication -->
        <div v-if="state.error" class="alert alert-danger">
          <i class="fas fa-exclamation-circle"></i>
          &nbsp;{{ state.error }}
        </div>

        <VueRecaptcha v-if="config.recaptcha.enabled" id="recaptcha" ref="recaptcha" :sitekey="config.recaptcha.sitekey" @verify="verify" />

        <div class="w-100 d-flex justify-content-center justify-content-md-start">
          <button type="submit" class="btn btn-speedify-blue my-3 mx-auto w-100 align-self-center stm-btn-login-local">
            Sign in
          </button>
        </div>
        <div class="w-100 text-center">
          <small>
            <router-link to="/password/forgot" class="text-decoration-none speedify-blue">Forgot your password?</router-link>
          </small>
        </div>
      </form>

      <!-- TODO: use feature toggles like from Featurevisor -->
      <a
        v-if="config.oidc.google.enabled"
        class="btn btn-speedify-outline mt-2 stm-btn-login-google"
        style="cursor: pointer"
        @click="loginWith('google')"
      >
        <img src="/images/google-logo.svg" style="width: 22px; height: 22px; min-width: 22px" />
        &nbsp; Sign in with Google
      </a>
      <a
        v-if="state.appleSigninEnabled"
        class="btn btn-speedify-outline mt-2 stm-btn-login-apple"
        style="cursor: pointer"
        @click="loginWith('apple')"
      >
        <img src="/images/apple-logo-grey.svg" style="width: 22px; height: 22px; min-width: 22px" />
        &nbsp; Sign in with Apple
      </a>
      <a
        v-if="config.oidc.zitadel.enabled"
        class="btn btn-speedify-outline mt-2 stm-btn-login-zitadel"
        style="cursor: pointer"
        @click="loginWith('zitadel')"
      >
        <img src="/images/zitadel-logo.svg" style="width: 22px; height: 22px; min-width: 22px" />
        &nbsp; Sign in for Enterprise
      </a>
    </div>
  </div>
</template>

<script setup lang="ts" charset="utf-8">
import { reactive, ref, onMounted } from 'vue';
import { useRoute, useRouter, LocationQueryValue, RouteLocationRaw } from 'vue-router';
import wretch from 'wretch';
import { loginRedirect } from '@/services/navigationGuards';

import { VueRecaptcha } from 'vue-recaptcha';

import { config } from '@/config';

// NOTE: optinal because of a bug https://github.com/Particular/ServicePulse/pull/1839
withDefaults(defineProps<{ showBody?: boolean }>(), { showBody: true });

const route = useRoute();
const router = useRouter();
const title = route.query.flow === 'register' ? 'Sign In to register your account!' : 'Sign In';
const hint = route.query.login_hint as LocationQueryValue;
let redirect = (route.query.redirect as LocationQueryValue) ?? '/';
const username = ref(hint && decodeURIComponent(hint));

const decodedRedirect = decodeURIComponent(route.query.redirect as string);
const params = new URL(decodedRedirect, window.location.origin).searchParams;
const hide = params.get('hide') as string;
const signin = params.get('signin') as string;
const storeurl = params.get('fromapp') === '1' ? 'speedify://speedify.com/in-app/store' : 'https://speedify.com/store';

const state = reactive<{
  error: string | null;
  appleSigninEnabled: boolean,
}>({
  error: null,
  appleSigninEnabled: hide !== 'apple' && config.oidc.apple.enabled,
});

let recaptchaResp = null as string | null; // how ridiculous?
const verify = (resp: string) => (recaptchaResp = resp);
const recaptcha = ref<VueRecaptcha>();

const login = async (event: Event) => {
  const formdata = new FormData(event.target as HTMLFormElement);
  recaptchaResp && formdata.set('g-recaptcha-response', recaptchaResp);
  await wretch('/login')
    .post(Object.fromEntries(formdata))
    .unauthorized(err => {
      state.error = err.json?.message;
      // Each reCAPTCHA user response token is valid for two minutes, and can
      // only be verified once to prevent replay attacks. If you need a new
      // token, you can re-run the reCAPTCHA verification.
      //
      // Otherwise we could receive the response body:
      // { success: false, 'error-codes': [ 'timeout-or-duplicate' ] }
      //
      // https://developers.google.com/recaptcha/docs/verify#token_restrictions
      recaptcha.value?.reset();
    })
    .internalError(err => {
      state.error = err.json?.message;
      recaptcha.value?.reset();
    })
    .res(() => {
      // handle redirection back to external urls like order.speedify if signing in from there
      void router.replace(loginRedirect(redirect) as RouteLocationRaw);
    })
    .catch(() => router.replace('/'));
};

onMounted(() => {
  if (signin && (signin === 'apple' || signin === 'google')) {
    params.delete('signin');
    redirect = redirect.split('?')[0] + '?' + params.toString();
    loginWith(signin);
  }
});

const loginWith = (provider: 'apple' | 'google' | 'zitadel') => {
  window.sessionStorage.setItem('redirect', redirect);
  window.location.href = `/auth/${provider}`;
};
</script>

<style>
div#recaptcha {
  display: flex;
  justify-content: center;
}

div#recaptcha > div {
  flex-grow: 1;
}
</style>
