<template>
  <LoadingComponent v-if="state.pendingStoreRefresh" text="Loading your account data" />
  <slot v-else />
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router';
import { onBeforeMount, reactive } from 'vue';
import { useRouter } from 'vue-router';

// TODO: styling
import LoadingComponent from '@/components/LoadingComponent.vue';
import { useUserStore } from '@/store/user';
import { useLinkedDevicesStore } from '@/store/linkedDevices';
import { getToken } from '@/services/backend';
import { config } from '@/config';

const vendors = config.partnerRouterVendors;
const router = useRouter();
const route = useRoute();
const userStore = useUserStore();
const linkedDevicesStore = useLinkedDevicesStore();
const state = reactive({
  pendingStoreRefresh: true,
  needsub: false,
});

onBeforeMount(async () => {
  try {
    await userStore.refresh();
    await linkedDevicesStore.awaitable; // guaranteed to init early and only once
    state.pendingStoreRefresh = false;

    const numRouterSubs = userStore.routerQty;
    const payload = await getToken();

    if (payload?.role === 'router' && vendors.includes(route.query.vendor as string) && route.query.deviceUUID) {
      return await router.push('/account#partner-90-day-activated=true');
    }

    if (payload?.role === 'router' && numRouterSubs === 0) {
      return await router.push('/account#show-router-dialogue=true');
    }

    if (payload?.role === 'router' && numRouterSubs < linkedDevicesStore.routers.length) {
      return await router.push('/account#show-router-dialogue=true');
    }

    // TODO: remove /welcome abstraction
    if (!userStore.subscriptions.length) {
      return await router.push('/welcome');
    }
  } catch (err) {
    console.error('Error getting user/subscription', err);
  }
});
</script>
