import { defineStore } from 'pinia';
import { UpgradeDetails } from '@/model';

type UpgradeSelection = {
  planCode: string;
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  details: UpgradeDetails | any; // FIXME: type
}

export default defineStore('upgradeSelection', {
  state: (): UpgradeSelection => ({ planCode: '', details: {} }),
  actions: {
    set(planCode: string, details: UpgradeDetails) {
      this.planCode = planCode;
      this.details = details;
    },
    reset() {
      this.planCode = '';
      this.details = {};
    },
    upgradeMonthlyPrice() {
      if (!this.planCode) {
        return undefined;
      }

      if (!this.details) {
        return undefined;
      }

      const pricePerMonth = (Number(this.details.price.replace('$', '')) / this.details.duration!).toFixed(2);
      return pricePerMonth;
    },
    discountedBlackfriday24UpgradePriceForQuantity(currentQuantity = 1) {
      if (!this.planCode) {
        return undefined;
      }
      const price = Number(this.details.discountPrice.replace('$', ''));
      let fullPrice = 0;
      let quantity = this.details.quantity || currentQuantity;
      if (this.details.title.toLowerCase().match(/teams/)) {
        quantity = currentQuantity;
      }
      if (this.details.title.toLowerCase().match(/families/)) {
        quantity = 1;
      }
      fullPrice = price * quantity;
      return fullPrice;
    },
    upgradePriceForQuantity(currentQuantity = 1) {
      if (!this.planCode) {
        return undefined;
      }
      const price = Number(this.details.price.replace('$', ''));
      let fullPrice = 0;
      let quantity = this.details.quantity || currentQuantity;
      if (this.details.title.toLowerCase().match(/teams/)) {
        quantity = currentQuantity;
      }
      if (this.details.title.toLowerCase().match(/families/)) {
        quantity = 1;
      }
      fullPrice = price * quantity;
      return fullPrice;
    },
    upgradesNextBillingPeriod() {
      if (!this.planCode) {
        return new Date();
      }

      if (!this.details) {
        return new Date();
      }

      const today = new Date();
      // Add details.duration months to today's date
      return (new Date(today.setMonth(today.getMonth() + this.details.duration!))).toDateString();
    },
    subscriptionBillingCycle() {
      if (!this.planCode) {
        return '';
      }

      // Subscription has been cancelled
      if (this.details.cancelable === 0) {
        return 'billed once';
      }

      if (this.details.planCode.match(/monthly/i)) {
        return 'billed monthly';
      }

      if (this.details.planCode.match(/yearly/i)) {
        return 'billed yearly';
      }

      if (this.details.planCode.match(/3year|threeyear/i)) {
        return 'billed every three years';
      }

      return 'billed once';
    },
  },
});
