<template>
  <div id="specialDealCard" class="card mb-3 bg-dark">
    <div class="card-body">
      <div class="d-flex mt-1 mb-4">
        <div class="d-flex flex-column align-items-start justify-content-center">
          <h6 class="text-start fw-bold" style="color: rgb(9, 227, 151);font-size:0.9em;">
            SEASON OF SAVINGS
          </h6>
          <div class="d-block text-start text-white">
            <strong class="fs-5">Save an additional 40% when you upgrade eligible plans to a yearly or multiyear plan</strong>
          </div>
        </div>
        <img src="/images/guy-with-gift.png" style="max-width: 30%" />
      </div>
    </div>
  </div>
</template>
