<template>
  <Banner />
  <IndividualSubscriptionManagementPortal v-if="subType === 'individual'" />
  <TeamManagementPortal v-if="subType === 'team'" :referenceId="referenceId" />
  <RouterManagementPortal v-if="subType === 'router'" />
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';

import Banner from '@/components/Banner.vue';
import { useUserStore } from '@/store/user';
import { useLinkedDevicesStore } from '@/store/linkedDevices';
import TeamManagementPortal from '@/components/TeamManagementPortal.vue';
import RouterManagementPortal from '@/components/RouterManagementPortal.vue';
import { Subscription } from '@/model';
import IndividualSubscriptionManagementPortal from '@/components/IndividualSubscriptionManagementPortal.vue';
import { useBanner } from './usebanner';
import RouterLicenseRequired from './banners/RouterLicenseRequired.vue';
import { getToken } from '@/services/backend';

const route = useRoute();
const banner = useBanner();
const { referenceId } = route.query as { [key: string]: string };
const { subscriptions } = useUserStore();

const subscription = computed<Subscription>(
  () => subscriptions.find(s => s.referenceId === referenceId)!,
);

onMounted(async () => {
  const userStore = useUserStore();
  const linkedDevicesStore = useLinkedDevicesStore();
  const numRouterSubs = userStore.routerQty;
  const payload = await getToken();

  if (payload?.role === 'router' && numRouterSubs === 0) {
    banner.open(RouterLicenseRequired, { props: { subscription } });
  } else if (payload?.role === 'router' && numRouterSubs < linkedDevicesStore.routers.length) {
    banner.open(RouterLicenseRequired, { props: { subscription } });
  }
});

const subType = computed(() => {
  if (subscription.value.type.match(/team/) || subscription.value.type.match(/fam/)) {
    return 'team';
  }
  if (subscription.value.type.match(/router/i)) {
    return 'router';
  }
  return 'individual';
});
</script>
