<template>
  <Accordion>
    <AccordionPanel :value="undefined" pt:root:class="border-bottom-0">
      <AccordionHeader pt:root:class="ps-1" pt:root:style="font-size: 2em">Available Add Ons</AccordionHeader>

      <AccordionContent pt:content:class="px-0 pb-0" pt:content:style="background: none">
        <div class="card mb-3">
          <div class="card-body">
            <div class="d-flex gap-2">
              <img src="@/assets/images/speedify-dedicated-server.svg" />
              <div class="d-flex row">
                <h4 class="my-1">Speedify Dedicated Server</h4>
                <span class="mb-3" style="color: #b2b2b2">Monthly and Yearly options available</span>
              </div>
            </div>
            <small class="mb-3">
              Dedicated Servers provide you, your family, or your team with increased speed and reliability, as well as port
              forwarding, and static IP addresses.
            </small>
            <router-link
              type="button"
              class="col-sm btn btn-speedify-grey mt-3 mx-1 stm-btn-view-billing href"
              :to="{}"
              @click.prevent="buyDediServer"
            >
              Add a Dedicated Server
            </router-link>
          </div>
        </div>

        <!-- TODO: Speedify for Routers Add On -->
        <div v-if="false" class="card mb-3">
          <div class="card-body">
            <div class="d-flex gap-2">
              <img src="@/assets/images/speedify-for-routers.svg" />
              <div class="row">
                <h4 class="my-1">Speedify for Routers</h4>
                <span class="mb-3" style="color: #b2b2b2">Multiple options available</span>
              </div>
            </div>
            <small class="mb-3">
              Activate your “Powered by Speedify” Router and share the bonded super connection with all devices on your network.
            </small>
            <router-link
              type="button"
              class="col-sm btn btn-speedify-grey mt-3 mx-1 stm-btn-view-billing href"
              :to="{ name: 'BillingView' }"
              @click.prevent="buyDediServer"
            >
              Add a Router License
            </router-link>
          </div>
        </div>
      </AccordionContent>
    </AccordionPanel>
  </Accordion>


  <!-- copy & pasted -->
  <div v-if="subscription">
    <div
      v-if="state.showPurchaseDediModal"
      id="purchaseDediModal"
      class="modal fade"
      data-bs-backdrop="static" data-bs-keyboard="false"
      tabindex="-1" aria-labelledby="purchaseDediLabel" aria-hidden="true"
      >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="purchaseDediLabel" class="modal-title">Please select a billing cycle</h5>
            <button
              type="button"
              class="btn-close stm-btn-add-dedi-cancel"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="dismissNewDedicatedServerModal"
              />
          </div>
          <div class="modal-body">
            <div class="form-floating mt-3">
              <div class="form-check">
                <div class="card h-100 my-2">
                  <div class="card-body pb-4">
                    <div class="card-title">
                      <input
                      id="newDediatedServerMonthly"
                      v-model="state.newDedicatedServer.timeInterval"
                      value="monthly"
                      class="form-check-input"
                      type="radio"
                      :checked="!subscription.productName.match(/year/)"
                      >
                      <label class="form-check-label stm-btn-add-dedi-monthly" for="newDediatedServerMonthly">
                        <h5>Monthly Dedicated Server</h5>
                        <small>$120 billed monthly</small>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="subscription.productName.match(/year/)" class="form-check">
                <div class="card h-100 my-2">
                  <div class="card-body pb-4">
                    <div class="card-title">
                      <input
                      id="newDediatedServerYearly"
                      v-model="state.newDedicatedServer.timeInterval"
                      value="yearly"
                      class="form-check-input"
                      type="radio"
                      checked
                      >
                      <label class="form-check-label stm-btn-add-dedi-yearly" for="newDediatedServerYearly">
                        <h5>Yearly Dedicated Server</h5>
                        <small>$900 billed yearly</small>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary stm-btn-add-dedi-cancel"
              data-bs-dismiss="modal"
              @click="dismissNewDedicatedServerModal"
              >
              Cancel
            </button>
              <button
                type="button"
                class="btn btn-speedify-blue stm-btn-add-dedi-confirm"
                :class="{ disabled: state.newDedicatedServer.timeInterval === '' }"
                data-bs-dismiss="modal"
                @click="purchaseDedicatedServerHandler('confirmDediModal')"
                >
                Purchase Dedicated Server
              </button>
          </div>
        </div>
      </div>
    </div>

    <!--Purchase confirmation modal-->
    <div
      v-if="state.showConfirmDediModal"
      id="confirmDediModal"
      class="modal fade"
      data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="confirmDediLabel" aria-hidden="true"
      >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="purchaseDediLabel" class="modal-title">Review Order</h5>
          </div>
          <div class="modal-body">
            <div class="card h-100">
              <div class="card-body pb-4">
                <div class="card-title d-flex justify-content-between">
                  <div>
                    <p v-if="state.newDedicatedServer.timeInterval=='yearly'">Yearly Dedicated Server</p>
                    <p v-if="state.newDedicatedServer.timeInterval=='monthly'">Monthly Dedicated Server</p>
                  </div>
                  <div>
                    <strong v-if="state.newDedicatedServer.timeInterval=='yearly'">$900.00</strong>
                    <strong v-if="state.newDedicatedServer.timeInterval=='monthly'">$120.00</strong>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-3">
              <small>
                Our system administrators will contact you to choose a location for your server. &nbsp;
                <a
                  href="https://speedify.com/for-teams/dedicated-vpn-servers/"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="text-decoration-none stm-btn-dedi-more"
                  >
                  Learn more
                </a>
              </small>
            </div>
          </div>
          <div class="modal-footer">
            <div v-if="state.newDedicatedServer.success" class="alert alert-success stm-msg-add-dedi-success" role="alert">
              A new dedicated server has successfully been purchased!
              We have notified our system administrators and you should be hearing back from them soon!
            </div>
            <div v-if="state.newDedicatedServer.failure" class="alert alert-danger stm-msg-add-dedi-failure" role="alert">
              Something went wrong when purchasing your dedicated server. Please try again later or contact support@speedify.com
              <hr>
              {{ state.newDedicatedServer.errorContext }}
            </div>
            <div v-if="subscription.storeType !== 'REC'" class="alert alert-danger stm-msg-add-dedi-non-rec-failure" role="alert">
              It seems like your subscription was purchased on our previous payment processor.
              In order to proceed with your dedicated server purchase, please contact support@speedify.com
            </div>
            <button
              v-if="!state.newDedicatedServer.success"
              type="button"
              class="btn btn-secondary stm-btn-add-dedi-cancel"
              data-bs-dismiss="modal"
              @click="dismissNewDedicatedServerModal"
              >
              Cancel
            </button>
              <button
                v-if="!state.newDedicatedServer.success"
                type="button"
                class="btn btn-speedify-blue stm-btn-add-dedi-confirm-final"
                :class="{
                disabled: state.newDedicatedServer.timeInterval === '' || subscription.storeType !== 'REC'
                }"
                @click="purchaseNewDedicatedServer()"
                >
                <span v-if="state.newDedicatedServer.pending" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Confirm Purchase
              </button>
              <button
                v-if="state.newDedicatedServer.success"
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
                @click="dismissNewDedicatedServerModal"
                >
                Done
              </button>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-between align-items-end my-3">
      <div class="col-lg-6">
        <h2 class="">Dedicated Servers</h2>
        <small>
          View usage and performance analytics. &nbsp;
          <a
            href="https://speedify.com/for-teams/dedicated-vpn-servers/"
            target="_blank"
            rel="noopener noreferrer"
            class="text-decoration-none stm-btn-dedi-more"
            >
            Learn more
          </a>
        </small>
      </div>
      <button
        v-if="isSubscriptionOwner(subscription)"
        type="button"
        class="btn btn-speedify-blue text-decoration-none stm-btn-add-dedi-existing-purchase-flow"
        @click="handleShowModal('purchaseDediModal')"
      >
        <i class="fas fa-plus"></i>&nbsp;Add a Dedicated Server
      </button>
    </div>

    <DedicatedServerPromotion v-if="promotionVisible" :subscription="subscription" @showModal="handleShowModal" />
    <DedicatedServersInfo v-else :subscription="subscription" @showModal="handleShowModal" />
  </div> <!-- copy & pasted -->



  <Dialog v-model:visible="subChooserVisible" modal header="Which subscription for the dedicated server?">
    <div class="d-flex row ps-3 mt-2">
      <RadioButtonGroup v-for="sub in eligibleSubs" :key="sub.referenceId" v-model="subChosen">
        <RadioButton :inputId="sub.referenceId" :value="sub.referenceId" name="subscription"/>
        <label :for="sub.referenceId">{{ humanReadableProductName(sub.productName) }}</label>
      </RadioButtonGroup>
    </div>


    <template #footer>
      <div class="container mb-4">
        <div class="row column-gap-3">
          <Button
            label="Cancel"
            class="col btn btn-speedify-grey stm-btn-buy-dedicated-server-subscription-confirm"
            style="flex: 1 1 0"
            @click="dismissSubChooser"
          />
          <Button
            :disabled="!subChosen"
            label="Confirm"
            class="btn btn-speedify-blue stm-btn-buy-dedicated-server-subscription-cancel"
            style="flex: 1 1 0"
            @click="confirm"
          />
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script lang="ts" setup>
import { computed, onMounted, reactive, ref } from 'vue';

import Accordion from 'primevue/accordion';
import AccordionPanel from 'primevue/accordionpanel';
import AccordionHeader from 'primevue/accordionheader';
import AccordionContent from 'primevue/accordioncontent';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import RadioButton from 'primevue/radiobutton';
import RadioButtonGroup from 'primevue/radiobuttongroup';

import { useUserStore } from '@/store/user';
import { Subscription } from '@/model';

const route = useRoute();
const router = useRouter();
const user = useUserStore();
const subscription = ref<Subscription | null>(null);
const subChooserVisible = ref(false);
const eligibleSubs = computed(() =>
  user.subscriptions
    .filter(s => s.active)
    .filter(s => s.type === 'individual' || (s.type === 'teams' && s.ownerUserid === user.userid) || s.type === 'router')
);
const subChosen = ref(null);

const removeQueryParamBuyDedicatedServer = () => {
  const query = new URLSearchParams(route.query as Record<string, string>);
  query.delete('buy-dedicated-server');
  const path = `${route.path}${query.size ? '?' : ''}${query.toString()}`

  void router.replace(path);
};

const confirm = () => {
  subChooserVisible.value = false;

  subscription.value = eligibleSubs.value.find(s => s.referenceId === subChosen.value)!;
  handleShowModal('purchaseDediModal');
  removeQueryParamBuyDedicatedServer();
};

const dismissSubChooser = () => {
  subChooserVisible.value = false;
  removeQueryParamBuyDedicatedServer();
};
const buyDediServer = () => subChooserVisible.value = true;

onMounted(() => {
  if (route.query['buy-dedicated-server'] === 'true') buyDediServer();
});


// NOTE:
// team member shown pitch asking team owner to buy a dedi server
// individual fine
// individual buy server -> any license you have can use it
// standalone dedi server card on dashboard
// detailed dedi server view in subs portal
// individual server -> share with team


// TODO: abstract copy & pasted code
import DedicatedServerPromotion from '@/components/DedicatedServerPromotion.vue';
import DedicatedServersInfo from '@/components/DedicatedServersInfo.vue';
import { purchaseDedicatedServer } from '@/services/backend';
import { humanReadableProductName, isOwner as isSubscriptionOwner  } from '@/services/subscription';
import { showModal } from '@/services/modal';
import { useRoute, useRouter } from 'vue-router';

const userStore = useUserStore();

const state = reactive({
  newDedicatedServer: {
    timeInterval: subscription.value?.productName.match(/year/i) ? 'yearly' : 'monthly',
    success: false,
    failure: false,
    errorContext: '',
    pending: false,
  },
  showPurchaseDediModal: false,
  purchaseNewDedicatedServer: false,
  showConfirmDediModal: false,
});

const dismissNewDedicatedServerModal = () => {
  state.newDedicatedServer.timeInterval = subscription.value?.productName.match(/yearly/i) ? 'yearly' : 'monthly';
  state.newDedicatedServer.success = false;
  state.newDedicatedServer.failure = false;
  state.newDedicatedServer.errorContext = '';
  state.purchaseNewDedicatedServer = false;
  state.showConfirmDediModal = false;
};

const purchaseNewDedicatedServer = async () => {
  state.newDedicatedServer.pending = true;
  const response = await purchaseDedicatedServer(subscription.value!.referenceId, state.newDedicatedServer.timeInterval);
  state.newDedicatedServer.pending = false;

  if (response.error) {
    state.newDedicatedServer.failure = true;
    // @ts-ignore
    state.newDedicatedServer.errorContext = response.message;
    return;
  }

  state.newDedicatedServer.success = true;
};

const purchaseDedicatedServerHandler = (elementID: string) => {
  const supportedStores = ['REC'];
  if (supportedStores.includes(subscription.value!.storeType)) {
    handleShowModal(elementID);
  } else {
    const cartRedirection = `${import.meta.env.VITE_CART_URL}/checkout/?plan=speedify-dedicated-server-${state.newDedicatedServer.timeInterval}`;
    window.location.replace(cartRedirection);
  }
}

const handleShowModal = (elementID: string) => {
  // @ts-ignore
  state[`show${elementID.charAt(0).toUpperCase()}${elementID.slice(1)}`] = true;
  showModal(elementID);
}

const promotionVisible = computed(() => {
  if (subscription.value?.type === 'teams' || subscription.value?.type === 'families') {
    if (subscription.value.ownerEmail.toLowerCase() === userStore.email.toLowerCase()) {
      return userStore.dedicatedServerQuantity === 0;
    }

    // we need to look at this so that admins (users that don't have ownership of the
    // servers subscriptions can still see the server panel
    // TODO: since the team members can't see the owner's subscriptions (including servers)
    // it is impossible to find out if the dedicated server sub is active *IF* there are straggling
    // team servers associated with the team in the database. we hope the operations team handles
    // this case like we expect
    return (userStore.teamServers?.filter(s => s.teamId === subscription.value?.team!.teamId))?.length === 0;
  }
  if (subscription.value?.type === 'individual') {
    return userStore.dedicatedServerQuantity === 0;
  }
  return false;
});
</script>
