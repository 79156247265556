<template>
  <div class="container">
    <div class="container d-flex vh-90 justify-content-center align-items-center">
      <div class="text-center">
        <div class="spinner-border text-info mb-3" role="status" />
        <br />
        <span>Signing in on another device</span>
        <br />
        <span>You may close this page after signing in succeeds</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { api } from '@/services/backend';

const route = useRoute();
const router = useRouter();
const id = route.params.id as string;

onMounted(async () => {
  await api
    .get(`/api/qrcode-login/${id}`)
    .unauthorized(() => router.push(`/login?redirect=${encodeURI(window.location.href)}`))
    .res();
});
</script>
